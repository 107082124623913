<template>
  <infinite-episodes />
</template>

<script>
  import InfiniteEpisodes from '../components/InfiniteEpisodes'

  export default {
    name: 'Episodes',

    components: {
      InfiniteEpisodes,
    },
  }
</script>
