<template>
  <v-container>
    <div v-for="(item, $index) in list" :key="$index">
      <v-card 
        hover 
        class="mb-3" 
        :height="item.clicked ? null : 145" 
        @click.stop="item.clicked = !item.clicked"
      >
        <v-card-title class="text-truncate">
          Season {{ $index < 11 ? 6 : 7 }}, Episode {{ ($index % 12) + 1 }}
        </v-card-title>
        <v-card-subtitle class="font-italic">
          <span class="green--text">{{ item.data.filter(x => x.new).length }} phrases replaced</span><br/>
          <span class="red--text">{{ item.data.filter(x => !x.new).length }} phrases too unique to replace</span>
        </v-card-subtitle>
        <v-card-text :class="item.clicked ? '' : 'd-inline-block text-truncate'">
          <p 
            v-for="(phrase, $iindex) in item.data" 
            :class="getPClass(item, phrase)" 
            :key="$iindex">
            {{ phrase.text }}
          </p>
        </v-card-text>
      </v-card>
    </div>
    <infinite-loading @infinite="infiniteHandler"></infinite-loading>
  </v-container>
</template>

<script>
  import axios from 'axios';

  const api = '//bachelorspeak.com/api/episodes';

  export default {
    name: 'InfiniteEpisodes',

    data: () => ({
      ep: 0,
      list: []
    }),
    methods: {
      getPClass(item, phrase) {
        let pClass = 'mr-2'
        if (!item.clicked) pClass += ' d-inline-block'
        if (phrase.new) pClass += ' green--text'
        else pClass += ' red--text'
        return pClass
      },
      infiniteHandler($state) {
        axios.get(api, {
          params: {
            ep: this.ep,
          },
        }).then(({ data }) => {
          if (data) {
            this.ep += 1;
            this.list.push({
              clicked: false,
              data: data.slice(1)
            });
            $state.loaded();
          } else {
            $state.complete();
          }
        }).catch(err => {
          $state.complete();
        });
      },
    },
  }
</script>
